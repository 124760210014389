
import React from 'react'
import Nav from '../../../../nav/nav'
import './product_one.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const pro_one_5 = () => {
  return (
<>
      <Nav/>
      <div className="container product_details">
      <img src="https://s.firealarmcdn.com/simplex/simplex-4007es-sell-sheet-a4-format-preview-0318495627-675x1260.webp" alt="" />
      <div className="heading">
        <h1>4007ES وحدة التحكم في إنذار الحريق
حل مدمج للحماية من الحرائق الكبيرة</h1>
      </div>
      <div className="paragraph">
        <p><FontAwesomeIcon icon={faAngleLeft} />  نظرًا لأن 4007ES يحتوي على قوائم UL متقاطعة مع معظم أجهزة الكشف التقليدية وقدرة مقاومة نهاية الخط قابلة للبرمجة، فيمكنه استبدال اللوحة التقليدية الحالية بأقل تكلفة وتعطيل. تشتمل جميع موديلات 4007ES على شاشة ملونة مدمجة تعمل باللمس تجعل الاستجابة لحالات الطوارئ وعرض تقارير النظام أمرًا بسيطًا للغاية.</p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> يوفر Simplex 4007ES القابل للتحكم والقابل للربط أداءً متقدمًا للمنشآت الأصغر في حزمة مدمجة وفعالة من حيث التكلفة. وهو يقدم مجموعة كاملة من الميزات، بما في ذلك تقنية الكشف والإخطار القابلة للتوجيه، وإمكانية التحكم في إطلاق قمع المخاطر المتعددة.  </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> يدعم 4007ES الاختبار الذاتي Simplex TrueAlert ES الرائد في الصناعة، وأجهزة الإشعارات القابلة للتوجيه، وأجهزة استشعار TrueAlarm القابلة للعنونة </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> قم بربط 4007ES مع وحدات التحكم في إنذار الحريق Simplex ومحطات العمل الرسومية الأخرى لإنشاء حل متصل لسلامة الحياة على مستوى الحرم الجامعي </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> حماية قوية ومرنة قابلة للتوجيه للمباني الصغيرة والمتوسطة الحجم </p>
      </div>
  </div>
</>
  )
}

export default pro_one_5