// import React, { useEffect } from 'react'
// import './comp.css';
// import Logo from '../../../imge/CONNEX-LOGO-8a15426a-108w.webp'
// import { Link } from "react-router-dom";
// const Comp = () => {
//   useEffect(() => {
//     let boxes = document.querySelectorAll(".scroll")

//     window.addEventListener("scroll" , checkBoxes)

//     function checkBoxes (){
//       const triggerBottom = window.innerHeight / 5 * 4 ; 

//       boxes.forEach( box => {
//         const boxTop = box.getBoundingClientRect().top;

//         if(boxTop < triggerBottom){
//           box.classList.add("show")
//         }else{
//           box.classList.remove("show")
//         }

//       })

//     }


//     checkBoxes()
//   })



//   return (
// <>
// <div className="row">
//     <div className="col-12">
//       <div className='heading'>
//         <h1>موزع معتمد 
// لكبري التوكيلات العالمية</h1>
//         {/* <h5>Connex L.T.d</h5> */}
//         <img src={Logo} alt="" />
//       </div>
//     </div>
//   </div>
//   <div className="container comp_row">
//      <Link  to='/johnson_controls'  className="  comp_img show right scroll ">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" />  
//     </Link>
//      <a  href='./company_one/index.html' className="  comp_img">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/simplex-logo-vector.svg" alt="" />  
//     </a>
//      <a  href='./company_two/index.html' className="  comp_img left scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/kidde_fire_systems_logo-1920w.jpg" alt="" />  
//     </a>
//      <a  href='./company_three/index.html' className="  comp_img right scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/Ansul-logo-1920w.png" alt="" />  
//     </a>
//      <Link  to={'General_Pumps'} className="  comp_img">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/General_Pumps_LOGO_JGwMpZR.svg" alt="" />  
//     </Link>
//      <a  href="./company_four/index.html" className="  comp_img left scroll">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/Reacton-Black-Red-Long-Logo-01.webp" alt="" />  
//     </a>
//      <Link  to={'reachtop'} className="  comp_img  right scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/oaz-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'fike'} className="  comp_img">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/fike-lightblue-logo.svg" alt="" />  
//     </Link>
//      <Link  to={'finder'} className="  comp_img left scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo_en-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'water_move'} className="  comp_img right scroll">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/General_Pumps_LOGO_JGwMpZR.svg" alt="" />  
//     </Link>
//      <Link  to={'Pumps'} className="  comp_img">
//         <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/6126ca65-717c-40aa-8f7c-a07a17e27373+%281%29.webp" alt="" />  
//     </Link>
//      <Link  to={'mueller'} className="  comp_img left scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/mueller_pms32_1-6121098a-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'infinova'} className="  comp_img  right scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo-45074d95-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'pumppeople'} className="  comp_img">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/pumppeople-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'BODET'} className="  comp_img  left scroll">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/Logo-BODET-1920w.png" alt="" />  
//     </Link>
//      <Link  to={'hunt'} className="  comp_img">
//         <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo1-1920w.jpg" alt="" />  
//     </Link>
//   </div>
// </>
//   )
// }

// export default Comp

import React, { useEffect } from 'react'
import './comp.css';
import Logo from '../../../imge/CONNEX-LOGO-8a15426a-108w.webp'
import { Link } from "react-router-dom";
const Comp = () => {
  useEffect(() => {
    let boxes = document.querySelectorAll(".scroll")

    window.addEventListener("scroll" , checkBoxes)

    function checkBoxes (){
      const triggerBottom = window.innerHeight / 5 * 4 ; 

      boxes.forEach( box => {
        const boxTop = box.getBoundingClientRect().top;

        if(boxTop < triggerBottom){
          box.classList.add("show")
        }else{
          box.classList.remove("show")
        }

      })

    }


    checkBoxes()
  })



  return (
<>
<div className="row">
    <div className="col-12">
      <div className='heading'>
        <h1>موزع معتمد 
لكبري التوكيلات العالمية</h1>
        {/* <h5>Connex L.T.d</h5> */}
        <img src={Logo} alt="" />
      </div>
    </div>
  </div>
  <div className="container comp_row">
     <Link  to='/johnson_controls'  className="  comp_img show right scroll ">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" />  
    </Link>
     <Link  to={'/simplex'} className="  comp_img">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/simplex-logo-vector.svg" alt="" />  
    </Link>
     <Link  to={'kidde_fire_systems'} className="  comp_img left scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/kidde_fire_systems_logo-1920w.jpg" alt="" />  
    </Link>
     <Link  to={'Ansul'} className="  comp_img right scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/Ansul-logo-1920w.png" alt="" />  
    </Link>
     <Link  to={'General_Pumps'} className="  comp_img">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/General_Pumps_LOGO_JGwMpZR.svg" alt="" />  
    </Link>
     <Link  to={'Reacton'} className="  comp_img left scroll">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/Reacton-Black-Red-Long-Logo-01.webp" alt="" />  
    </Link>
     <Link  to={'reachtop'} className="  comp_img  right scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/oaz-1920w.png" alt="" />  
    </Link>
     <Link  to={'fike'} className="  comp_img">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/fike-lightblue-logo.svg" alt="" />  
    </Link>
     <Link  to={'finder'} className="  comp_img left scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo_en-1920w.png" alt="" />  
    </Link>
     <Link  to={'water_move'} className="  comp_img right scroll">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/General_Pumps_LOGO_JGwMpZR.svg" alt="" />  
    </Link>
     <Link  to={'Pumps'} className="  comp_img">
        <img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/6126ca65-717c-40aa-8f7c-a07a17e27373+%281%29.webp" alt="" />  
    </Link>
     <Link  to={'mueller'} className="  comp_img left scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/mueller_pms32_1-6121098a-1920w.png" alt="" />  
    </Link>
     <Link  to={'infinova'} className="  comp_img  right scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo-45074d95-1920w.png" alt="" />  
    </Link>
     <Link  to={'pumppeople'} className="  comp_img">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/pumppeople-1920w.png" alt="" />  
    </Link>
     <Link  to={'BODET'} className="  comp_img  left scroll">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/Logo-BODET-1920w.png" alt="" />  
    </Link>
     <Link  to={'hunt'} className="  comp_img">
        <img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/logo1-1920w.jpg" alt="" />  
    </Link>
  </div>
</>
  )
}

export default Comp