import React from 'react'
import Nav from '../../../../nav/nav'
import './product_one.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const pro_one_3 = () => {
  return (
<>
      <Nav/>
      <div className="container product_details">
      <img src="https://www.tycofpp.com/uploads/block-content/ES_TSD_invite_banner_27_Oct.jpg" alt="" />
      <div className="heading">
        <h1>وحدة التحكم في إنذار الحريق Simplex 4100ES
الشركة الرائدة في مجال الكشف عن الحرائق القابلة للعنونة</h1>
      </div>
      <div className="paragraph">
        <p><FontAwesomeIcon icon={faAngleLeft} /> بالإضافة إلى دعم ما يصل إلى 3000 نقطة قابلة للعنونة، فإن جهاز 4100ES قابل للربط بالشبكة، ويوفر إعلامًا صوتيًا/قدرة صوتية متكاملة، وهواتف رجال الإطفاء، وهو مدرج للتحكم في إطلاق القمع متعدد المخاطر. توفر شاشة اللمس الملونة الاختيارية الكبيرة والقابلة للتكوين قدرًا كبيرًا من المعلومات وواجهة بديهية وسهلة الاستخدام.</p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> تم تصميم Simplex 4100ES للتعامل مع متطلبات المنشأة المعقدة بسهولة، مما يوفر تقنية قابلة للتوجيه ومرونة تصميم قابلة للتطوير إلى المرافق المتوسطة والكبيرة وشبكات الحرم الجامعي متعددة المباني.</p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> تدعم الشبكات عالية السرعة المستندة إلى بروتوكول الإنترنت (IP) الاتصالات الهاتفية المتقدمة للبيانات والصوت ورجال الإطفاء </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> يعمل التوافق الأمامي/الخلفي على حماية استثمارك ويسهل عليك مواكبة أحدث التقنيات </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> حل مثالي وقابل للتوجيه للمباني الكبيرة والجامعات والتطبيقات التي تتطلب الإخلاء الصوتي </p>
      </div>
  </div>
</>
  )
}

export default pro_one_3