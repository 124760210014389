import React from 'react'
import Nav from '../../../../nav/nav'
import './product_one.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const pro_one_4 = () => {
  return (
<>
      <Nav/>
      <div className="container product_details">
      <img src="https://5.imimg.com/data5/SELLER/Default/2023/6/316086292/LW/MJ/GJ/88917888/simplex-4010es-fire-alarm-control-unit.jpg" alt="" />
      <div className="heading">
        <h1>4010ES وحدة التحكم في إنذار الحريق
الخيار الأمثل للمباني متوسطة الحجم</h1>
      </div>
      <div className="paragraph">
        <p><FontAwesomeIcon icon={faAngleLeft} /> توفر وحدة التحكم في إنذار الحريق Simplex 4010ES والمذيع عن بعد أداءً رائعًا، بما في ذلك أجهزة الاستشعار والأجهزة الذكية، وتصميم أسهل، وتركيب أسرع، وصيانة أبسط، كل ذلك في حزمة مصممة خصيصًا لتلبية احتياجات المباني متوسطة الحجم.</p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> بفضل قدرة الشبكات القوية، يمكن ربط 4010ES بمئات من وحدات التحكم Simplex ES الأخرى ووحدات تحكم الإدارة الرسومية TrueSite Workstation لإنشاء نظام سلامة حياة مُدار مركزيًا على مستوى الحرم الجامعي. تضع شاشة اللمس الملونة الاختيارية جميع المعلومات وعناصر التحكم التي تحتاجها في متناول يدك.</p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> يوفر 4010ES جميع مزايا إمكانية التوجيه من خلال أجهزة استشعار TrueAlarm القابلة للتوجيه وأجهزة إعلام TrueAlert ES </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> تتيح لك قابلية التوسع استيعاب النمو من 250 إلى 1000 نقطة </p>
        <p><FontAwesomeIcon icon={faAngleLeft} /> توفر شبكات IP السرعة والمرونة في كل مرحلة </p>
      </div>
  </div>
</>
  )
}

export default pro_one_4