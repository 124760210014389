import './App.css';
import Home from './component/content/index';
import {Routes , Route} from "react-router-dom"
import Johnson_controls from './component/content/company/johnson-controls/johnson-controls';
import Simplex from './component/content/company/simplex/simplex';
import Pro_one_1 from './component/content/company/simplex/product_one/pro_one_1'
import Pro_one_2 from './component/content/company/simplex/product_one/pro_one_2'
import Pro_one_3 from './component/content/company/simplex/product_one/pro_one_3'
import Pro_one_4 from './component/content/company/simplex/product_one/pro_one_4'
import Pro_one_5 from './component/content/company/simplex/product_one/pro_one_5'
// import Pro_one_6 from './component/content/company/simplex/product_one/pro_one_6'
// import Pro_one_7 from './component/content/company/simplex/product_one/pro_one_2'
// import Pro_one_8 from './component/content/company/simplex/product_one/pro_one_3'
// import Pro_one_9 from './component/content/company/simplex/product_one/pro_one_4'
// import Pro_one_10 from './component/content/company/simplex/product_one/pro_one_5'
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="johnson_controls" element={<Johnson_controls />} />
      <Route path="simplex" element={<Simplex />} />
      <Route path="/simplex/pro_one_1" element={<Pro_one_1/>} />
      <Route path="/simplex/pro_one_2" element={<Pro_one_2/>} />
      <Route path="/simplex/pro_one_3" element={<Pro_one_3/>} />
      <Route path="/simplex/pro_one_4" element={<Pro_one_4/>} />
      <Route path="/simplex/pro_one_5" element={<Pro_one_5/>} />
      </Routes>
    </div>
  );
}

export default App;
