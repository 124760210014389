import React from 'react'
import Nav from '../../../../nav/nav'
import './product_one.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const pro_one_1 = () => {
  return (
    <>
    <Nav/>
    <div className="container product_details">
        <img src="https://preview.redd.it/new-simplex-and-autocall-panel-series-programmable-by-v0-kgr80mu03t791.jpg?width=640&crop=smart&auto=webp&s=84b541c0b95ec39856eccff1b44ef7e735feaf37" alt="" />
        <div className="heading">
          <h1>وحدة التحكم في إنذار الحريق Simplex 2050FS
الشركة الرائدة في مجال الكشف عن الحرائق القابلة للعنونة</h1>
        </div>
        <div className="paragraph">
          <p><FontAwesomeIcon icon={faAngleLeft} /> لم يعد حجم المشروع عائقًا أمام الحصول على جودة وأداء Simplex. قامت شركة Simplex بإنشاء مجموعة كاملة من وحدات وأجهزة التحكم في إنذار الحريق التي تعزز الموثوقية وقابلية التوسع في حل ذي قيمة عالية للمشاريع ذات المساحة الأصغر. </p>
          <p><FontAwesomeIcon icon={faAngleLeft} /> يمكن للمنشآت ذات احتياجات سلامة الحياة الأقل تعقيدًا أن تحصل على حماية قابلة للتوجيه باستخدام وحدة التحكم في إنذار الحريق Simplex 2050FS، التي تدعم ما يصل إلى 50 نقطة. </p>
          <p><FontAwesomeIcon icon={faAngleLeft} /> توفر مقاطع الفيديو التعليمية القصيرة عبر الإنترنت والبرمجة القائمة على التطبيقات سهولة التثبيت والتكوين للفنيين. </p>
          <p><FontAwesomeIcon icon={faAngleLeft} /> مصممة للمباني التي يقل ارتفاعها عن أربعة طوابق وتقل مساحتها عن 25000 قدم مربع. </p>
          <p><FontAwesomeIcon icon={faAngleLeft} /> حماية مصممة لتلبية احتياجات منشآتك الصغيرة والمتوسطة الحجم. </p>
        </div>
    </div>
    </>
  )
}

export default pro_one_1