import React , { useEffect } from 'react'
import './simplex.css';
import Nav from '../../../nav/nav'
import { Link } from 'react-router-dom';
// import data from '../../../../api/api.json'
// console.log(data.company[0].simplex[0].item)
const Simplex = () => {

  useEffect(() => {
    let boxes = document.querySelectorAll(".scroll")

    window.addEventListener("scroll" , checkBoxes)

    function checkBoxes (){
      const triggerBottom = window.innerHeight / 5 * 4 ; 

      boxes.forEach( box => {
        const boxTop = box.getBoundingClientRect().top;

        if(boxTop < triggerBottom){
          box.classList.add("show")
        }else{
          box.classList.remove("show")
        }

      })

    }

    checkBoxes()

  })

  return (
    <>
    <Nav/>
    <div className="intro">
      <div className="img">
      <img src="https://www.laboratuar.com/images/en-54-25-yangin-algilama-ve-yangin-alarm-sistemleri-bolum-25:-radyo-baglantilarini-kullanan-bilesenler-icin-standart-test.jpg" alt="" />
      </div>
      <div className="section_text ">
        <h1>حلول الكشف عن الحرائق وسلامة الحياة التي تعلم أنه يمكنك الوثوق بها</h1>
        <p>في عالم الحماية من الحرائق، تعد Simplex مرادفًا للابتكار والقيمة. اكتشف سبب اعتماد المؤسسات الرائدة حول العالم على أنظمة Simplex لسلامة الحياة لحماية أفرادها وممتلكاتها.</p>
      </div>
      {/* <div className="section_category">معدات التدفئة والتهوية وتكييف الهواء</div> */}
      <div className="section_logo"><img src="https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/simplex-logo-vector.svg" alt="" /></div>
    </div>
    <div className="heading">
<h1>  تصفح حلول الكشف عن الحرائق لدينا</h1>
  </div>
  <div className="container comp_row">

    {/* {
    data.company[0].simplex.map( product => {
      return(
        <div key={product.id}>
    <div className="  comp_img  right scroll " style={{cursor:"unset"}}>
        <img src={product.img} alt="" />  
        <p>{product.peoductTitle}</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target={`#${product.btn_id}`} aria-controls={product.btn_id}>تصفح</button>
    </div>
        </div>
      )
    } )  
    } */}



     <div  className="  comp_img  right scroll " style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_4100.png?h=250&w=347&la=en&hash=0A400E358B7AF3F994AC820461D2F2BE" alt="" />  

        <p>أنظمة قابلة للعنونة</p>

        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions1" aria-controls="offcanvasWithBothOptions1">تصفح</button>

    </div>

     <div className="  comp_img" style={{cursor:"unset"}}>

        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_4006.png?h=250&w=347&la=en&hash=C61CA6DF5B84A98E2F6D0493115CF9AC" alt="" />  

        <p>الأنظمة التقليدية</p>

        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions2" aria-controls="offcanvasWithBothOptions2">تصفح</button>
    </div>

     <div className="  comp_img left scroll" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_sena_1.png?h=250&w=347&la=en&hash=C18AE9470B672EAC42369041887E50BD" alt="" />  
        <p>أجهزة الاستشعار والقواعد القابلة للعنونة</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions3" aria-controls="offcanvasWithBothOptions3">تصفح</button>
    </div>

     <div className="  comp_img right scroll" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_psa_2.png?h=250&w=347&la=en&hash=9795C5111BBC4CBE175028AF7473A560" alt="" />  
        <p>محطات سحب ونقاط اتصال قابلة للعنونة</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions4" aria-controls="offcanvasWithBothOptions6">تصفح</button>
    </div>

     <div className="  comp_img" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_senc.png?h=250&w=347&la=en&hash=0E980C70C07538BA454CA827ED9F3BEE" alt="" />  
        <p>أجهزة الاستشعار والقواعد التقليدية</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions5" aria-controls="offcanvasWithBothOptions5">تصفح</button>
    </div>

     <div className="  comp_img left scroll" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_taio_4.png?h=250&w=347&la=en&hash=97AAFD46068701194F2D1E738542E2D0" alt="" />  
        <p> الأجهزة الطرفية القابلة للعنونة</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions6" aria-controls="offcanvasWithBothOptions6">تصفح</button>
    </div>

     <div className="  comp_img  right scroll" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_psc_1.png?h=250&w=347&la=en&hash=1A3CBAC22DA495E57C2A02AA29BBB54A" alt="" />  
        <p>محطات السحب التقليدية ونقاط الاتصال</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions7" aria-controls="offcanvasWithBothOptions7">تصفح</button>
    </div>

     <div className="  comp_img" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_naes_2_v1.png?h=250&w=347&la=en&hash=5C15C566A924C9FF3BCDFE95F6A07759" alt="" />  
        <p>إشعار قابل للعنونة</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions8" aria-controls="offcanvasWithBothOptions8">تصفح</button>
    </div>

     <div className="  comp_img left scroll" style={{cursor:"unset"}}>
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_product_gallery_nac_1.png?h=250&w=347&la=en&hash=A75441F3729DD619A84DE8A628D5B9EF" alt="" />  
        <p>الإخطار التقليدي</p>
        <button className="btn btn-danger popup" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions9" aria-controls="offcanvasWithBothOptions9">تصفح</button>
    </div>


{/* ******************************************************************************************************************** */}

{/**********************************************  product_one  ***********************************************************/}


      {/* {
      data.company[0].simplex.map( product => {
        // console.log(product.item.map( pro => pro.description));
        return(
        <div key={product.id} className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id={product.btn_id} aria-labelledby={product.labelledby} style={{height: "450px"}}>
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id={product.labelledby}>{product.peoductTitle}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body small d-xxl-flex overflow-hidden">

          </div>
      </div>
        )
        
      })  
    } */}


    {/* <Link to='/simplex/pro_one_1' className="  comp_img scrollup">
          <img src={product.item.map( pro => pro.img)} alt="" />  
          <p>{product.item.map( pro => pro.description)}</p>
    </Link> */}
{/* // <Link to='/simplex/pro_one_1' className="  comp_img scrollup">
//         <img src={product.item.map( pro => pro.img)} alt="" />  
//         <p>{product.item.map( pro => pro.description)}</p>
//   </Link>  */}
    {/* <Link to='/simplex/pro_one_1' className="  comp_img scrollup">
          <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2050fs.png?h=1117&w=1700&la=en&hash=BCD6B83ECAD6AB89802881937276179C" alt="" />  
          <p> 2050FS وحدة التحكم في إنذار الحريق</p>
      </Link>
    <Link to='/simplex/pro_one_2' className="  comp_img scrollup">
          <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2050fs.png?h=1117&w=1700&la=en&hash=BCD6B83ECAD6AB89802881937276179C" alt="" />  
          <p> 2250FS وحدة التحكم في إنذار الحريق</p>
      </Link>
    <Link to='/simplex/pro_one_3' className="  comp_img scrollup">
          <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_4100.png?h=268&w=408&la=en&hash=65E5F09B5F84401484E120F1F7DBBF93" alt="" />  
          <p>4100ES وحدة التحكم في إنذار الحريق</p>
      </Link>
    <Link to='/simplex/pro_one_4' className="  comp_img scrollup">
          <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4010.png?h=268&w=408&la=en&hash=2BFD73E6C0BFE0180B0413E063154199" alt="" />  
          <p> 4010ES وحدة التحكم في إنذار الحريق </p>
      </Link>
    <Link to='/simplex/pro_one_5' className="  comp_img scrollup">
          <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4007.png?h=268&w=408&la=en&hash=22B67C5DAF7737D3897F2FDD1AD7778F" alt="" />  
          <p>  4007ES وحدة التحكم في إنذار الحريق</p>
      </Link> */}

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions1" aria-labelledby="offcanvasWithBothOptionsLabel1" style={{height: "450px"}}>
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel1">أنظمة قابلة للعنونة</h5>
    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body small d-xxl-flex overflow-hidden">
  <Link to='/simplex/pro_one_1' className="  comp_img scrollup">
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2050fs.png?h=1117&w=1700&la=en&hash=BCD6B83ECAD6AB89802881937276179C" alt="" />  
        <p> 2050FS وحدة التحكم في إنذار الحريق</p>
    </Link>
  <Link to='/simplex/pro_one_2' className="  comp_img scrollup">
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2050fs.png?h=1117&w=1700&la=en&hash=BCD6B83ECAD6AB89802881937276179C" alt="" />  
        <p> 2250FS وحدة التحكم في إنذار الحريق</p>
    </Link>
  <Link to='/simplex/pro_one_3' className="  comp_img scrollup">
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_4100.png?h=268&w=408&la=en&hash=65E5F09B5F84401484E120F1F7DBBF93" alt="" />  
        <p>4100ES وحدة التحكم في إنذار الحريق</p>
    </Link>
  <Link to='/simplex/pro_one_4' className="  comp_img scrollup">
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4010.png?h=268&w=408&la=en&hash=2BFD73E6C0BFE0180B0413E063154199" alt="" />  
        <p> 4010ES وحدة التحكم في إنذار الحريق </p>
    </Link>
  <Link to='/simplex/pro_one_5' className="  comp_img scrollup">
        <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4007.png?h=268&w=408&la=en&hash=22B67C5DAF7737D3897F2FDD1AD7778F" alt="" />  
        <p>  4007ES وحدة التحكم في إنذار الحريق</p>
    </Link>
  </div>
    </div>

{/**********************************************  product_two  ***********************************************************/}

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions2" aria-labelledby="offcanvasWithBothOptionsLabel2" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel2">الأنظمة التقليدية</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <Link to='/simplex/pro_one_6' className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2004fs.png?h=1117&w=1700&la=en&hash=4D9FB5CE2A54E10F90A7D2F66D1ED694" alt="" />  
              <p> 2004FS وحدة التحكم في إنذار الحريق</p>
        </Link>
        <Link to='/simplex/pro_one_7' className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_2008fs.png?h=1117&w=1700&la=en&hash=F600C297C0AFACFCB9A4B1EEE747330A" alt="" />  
              <p> 2008FS وحدة التحكم في إنذار الحريق </p>
        </Link>
        <Link to='/simplex/pro_one_8' className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4004.png?h=268&w=408&la=en&hash=6FED44BD2EC44CE3D2A0DDCF8C10A380" alt="" />  
              <p> 4004R نظام تحرير القمع</p>
        </Link>
        <Link to='/simplex/pro_one_9' className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4003.png?h=268&w=408&la=en&hash=323069E08DE3029C9470E40D439F10E6" alt="" />  
              <p> 4003EC نظام التحكم الصوتي</p>
        </Link>
        <Link to='/simplex/pro_one_10' className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_4006.png?h=268&w=408&la=en&hash=96C61B4C4CE97BBB85BA16E426E11CB4" alt="" />  
              <p> 4006 وحدة التحكم في إنذار الحريق </p>
        </Link>
      </div>
    </div>

{/**********************************************  product_three  ***********************************************************/}

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions3" aria-labelledby="offcanvasWithBothOptions3" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel3">أجهزة الاستشعار والقواعد القابلة للعنونة</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_sena_1.png?h=268&w=408&la=en&hash=213D3913342B3E75129BC6ADDF5AC1BA" alt="" />  
              <p> أجهزة استشعار وقواعد TrueAlarm القابلة للعنونة </p>
        </div>
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_senx_1.png?h=268&w=408&la=en&hash=BF03C2BA5A3C0D3F92FE054886198210" alt="" />  
              <p>  حلول الاستشعار المتخصصة القابلة للعنونة </p>
        </div>
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_mxs_2.png?h=268&w=408&la=en&hash=6C0A42F0396E54F1168D7DF48798AF77" alt="" />  
              <p> مستشعرات وقواعد MX القابلة للعنونة </p>
        </div>
      </div>
    </div>

{/**********************************************  product_four  ***********************************************************/}

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions4" aria-labelledby="offcanvasWithBothOptions4" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel4">محطات سحب ونقاط اتصال قابلة للعنونة </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_psa_1.jpg?h=268&w=408&la=en&hash=CA4D8501CEF4869D1AFF31FC9846BFEE" alt="" />  
              <p> محطات سحب TrueAlarm قابلة للعنونة</p>
        </div>
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_mxcp_1.jpg?h=268&w=408&la=en&hash=943AD31192095066D4BC9AC8461E83F1" alt="" />  
              <p> نقاط اتصال MX القابلة للعنونة </p>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions5" aria-labelledby="offcanvasWithBothOptions5" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel5">الأجهزة الطرفية القابلة للعنونة </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_senc.jpg?h=268&w=408&la=en&hash=0AF8986D1103AC6BE9D0B1B30BD61348" alt="" />  
              <p> أجهزة الاستشعار والقواعد التقليدية TrueAlarm </p>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions6" aria-labelledby="offcanvasWithBothOptions6" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel6"> الأجهزة الطرفية القابلة للعنونة </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_taio_1.png?h=268&w=408&la=en&hash=0098029C5F5A09301987AAACB66E3066" alt="" />  
              <p> وحدات الإدخال/الإخراج والأجهزة الطرفية TrueAlarm  </p>
        </div>
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_mxio.jpg?h=268&w=408&la=en&hash=CDF72717D262AAFCAC5178CA6C6C4E10" alt="" />  
              <p> وحدات الإدخال/الإخراج MX والأجهزة الطرفية  </p>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions7" aria-labelledby="offcanvasWithBothOptions7" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel7"> محطات السحب التقليدية ونقاط الاتصال </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_psc_1.jpg?h=268&w=408&la=en&hash=1DC43BD3EB8523D994F8863640C4DFA1" alt="" />  
              <p> محطات السحب التقليدية TrueAlarm  </p>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions8" aria-labelledby="offcanvasWithBothOptions8" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel8"> إشعار قابل للعنونة </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery/bts_productgallery_red_audio_visual_fire_straight_gray-removebg-preview.png?h=268&w=408&la=en&hash=DD2A5098157074F8A4ED21C968A515E7" alt="" />  
              <p> TrueAlert ES أجهزة الإشعارات ومكبرات الصوت القابلة للعنونة  </p>
        </div>
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_product_gallery_card_natm.jpg?h=268&w=408&la=en&hash=263465A46C5BD94B2659EE01B4201438" alt="" />  
              <p>  TrueAlert لحلول المراسلة المرئية </p>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions9" aria-labelledby="offcanvasWithBothOptions9" style={{height: "450px"}}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel9"> الإخطار التقليدي </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body small d-xxl-flex overflow-hidden">
        <div className="  comp_img scrollup">
              <img src="https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/product-gallery-card/bts_productgallery_nac_5.png?h=268&w=408&la=en&hash=AC7A211CD0C798930954CBD027F84533" alt="" />  
              <p> أجهزة ومكبرات الصوت للإشعارات غير القابلة للعنونة من TrueAlert  </p>
        </div>
      </div>
    </div>




  </div>
    </>
  )
}

export default Simplex