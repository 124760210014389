import React from 'react'
import './nav.css';
import Logo from '../../imge/CONNEX-LOGO-8a15426a-108w.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock , faBuildingShield , faTruckFast} from '@fortawesome/free-solid-svg-icons'
const nav = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src={Logo} alt="" /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse pull-right" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">الرئيسية</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">مدونة</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">تواصل معنا</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            خدماتنا
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">نظام إنذار ألي</a></li>
            <li><a className="dropdown-item" href="#">نظام إطفاء ألي</a></li>
            <li><a className="dropdown-item" href="#">نظم التيار الخفيف</a></li>
            <li><a className="dropdown-item" href="#">كاميرات مراقبة</a></li>
            <li><a className="dropdown-item" href="#">تكيفات مركزية</a></li>
            <li><a className="dropdown-item" href="#">طلمبات</a></li>
            <li><a className="dropdown-item" href="#">صوتيات</a></li>
          </ul>
        </li>
      <div className="about">
        <ul>
          <li> <FontAwesomeIcon icon={faTruckFast} /> شركة كونكس العالمية للهندسة والتجارة </li>
          <li> <FontAwesomeIcon icon={faLock} /> ضمان - راحة - أمان </li>
          <li> <FontAwesomeIcon icon={faBuildingShield} /> أنظمة أمنية وحلول متقدمة </li>
        </ul>
      </div>
      </ul>
    </div>
  </div>
</nav>
  )
}

export default nav